import Work1 from "../../assets/work1.webp"
import Work2 from "../../assets/work2.webp"
import Work3 from "../../assets/work3.webp"
import Work4 from "../../assets/work4.webp"
import Work5 from "../../assets/work5.webp"
import Work6 from "../../assets/work6.webp"

// Data of projects and filters
export const projectsData = [
    {
        id: 1,
        image: Work1,
        title: 'À la base - Nekfeu',
        category: 'Frontend',
        link: 'https://benedettir.emf-informatique.ch/101/',
    },

    {
        id: 2,
        image: Work2,
        title: 'Spotify API',
        category: 'Frontend',
        link: 'https://benedettir.emf-informatique.ch/307/',
    },

    {
        id: 3,
        image: Work3,
        title: 'TourisTunes project',
        category: 'Backend',
        link: 'https://benedettir.emf-informatique.ch/151/client/',
    },

    {
        id: 4,
        image: Work4,
        title: 'Tik & Tak Records',
        category: 'Backend',
        link: 'https://benedettir.emf-informatique.ch/java_client/',
    },

    {
        id: 5,
        image: Work5,
        title: 'Gym Châtonnaye',
        category: 'Other',
        link: 'https://gymchatonnaye.ch/',
    },

    {
        id: 6,
        image: Work6,
        title: ' Project Phone Game - Dont Be 21',
        category: 'Other',
        link: 'https://romarin.click/dont_be_21/',
    },
];

export const projectsNav = [
    {
        name: 'all',
    },

    {
        name: 'Frontend',
    },

    {
        name: 'Backend',
    },

    {
        name: 'Other',
    },
];
