import React from 'react'

const Social = () => {
    return (
        <div className="home__social">
            <a href="https://www.linkedin.com/in/benedetti-romain/" className="home__social-icon" target="_blank">
                <i class="uil uil-linkedin-alt"></i>
            </a>

            <a href="https://www.instagram.com/romarin.01/" className="home__social-icon" target="_blank">
                <i class="uil uil-instagram"></i>
            </a>

            <a href="https://github.com/Touristiquer" className="home__social-icon" target="_blank">
                <i class="uil uil-github-alt"></i>
            </a>

            <a href="https://www.youtube.com/channel/UCA6msX_DJQ2dUfDrkOJLk6g" className="home__social-icon" target="_blank">
                <i class="uit uil-youtube"></i>
            </a>

            <a href="https://open.spotify.com/intl-fr/artist/7npGSjstCone2pNoNaYoSJ" className="home__social-icon" target="_blank">
                <i class='bx bxl-spotify'></i>
            </a>

            <a href="https://soundcloud.com/touristiquement" className="home__social-icon" target="_blank">
                <i class='bx bxl-soundcloud'></i>
            </a>
        </div>
    )
}

export default Social