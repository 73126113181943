import React from 'react';

const Backend = () => {
    return (
        <div className="skills__content">
            <h3 className="skills__title">Backend developer</h3>

            <div className="skills__box">
                <div className="skills__group">
                    {/* PHP skill */}
                    <div className="skills__data">
                        <i className='bx bxs-badge-check'></i>

                        <div>
                            <h3 className="skills__name">PHP</h3>
                            <div className="skills__level">Intermediate</div>
                        </div>
                    </div>

                    {/* Node.js skill */}
                    <div className="skills__data">
                        <i className='bx bxs-badge-check'></i>

                        <div>
                            <h3 className="skills__name">Node.js</h3>
                            <div className="skills__level">Basic</div>
                        </div>
                    </div>

                    {/* ExpressJS skill */}
                    <div className="skills__data">
                        <i className='bx bxs-badge-check'></i>

                        <div>
                            <h3 className="skills__name">ExpressJS</h3>
                            <div className="skills__level">Basic</div>
                        </div>
                    </div>
                </div>

                <div className="skills__group">
                    {/* MySQL skill */}
                    <div className="skills__data">
                        <i className='bx bxs-badge-check'></i>

                        <div>
                            <h3 className="skills__name">MySQL</h3>
                            <div className="skills__level">Intermediate</div>
                        </div>
                    </div>

                    {/* APIs skill */}
                    <div className="skills__data">
                        <i className='bx bxs-badge-check'></i>

                        <div>
                            <h3 className="skills__name">APIs</h3>
                            <div className="skills__level">Basic</div>
                        </div>
                    </div>

                    {/* Nginx skill */}
                    <div className="skills__data">
                        <i className='bx bxs-badge-check'></i>

                        <div>
                            <h3 className="skills__name">Nginx</h3>
                            <div className="skills__level">Basic</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Backend;
