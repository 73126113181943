import React from 'react';
import './App.css';
import Header from './components/header/Header';
import Home from './components/home/Home';
import About from './components/about/About';
import Skills from './components/skills/Skills';
import Qualification from './components/qualification/Qualification';
import Contact from './components/contact/Contact';
import Footer from './components/footer/Footer';
import ScrollUp from './components/scrollup/ScrollUp';
import Work from './components/work/Work';

const App = () => {
    return (
        <>
            <Header />
            {/* Main content */}
            <main className='main'>
                <Home />
                <About />
                <Skills />
                <Qualification />
                <Work />
                <Contact />
            </main>
            <Footer />

            {/* ScrollUp component */}
            <ScrollUp />
        </>
    )
}

export default App;
