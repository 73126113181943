import React from 'react';
import "./skills.css";
import Frontend from './Frontend';
import Backend from './Backend';
import { Reveal } from '../utils/Reveal';

const Skills = () => {
    return (
        <section className="skills section" id="skills">
            {/* Title and subtitle of the skills section */}
            <Reveal>
                <h2 className="section__title">Skills</h2>
                <span className="section__subtitle">My technical level</span>
            </Reveal>
            {/* Container for frontend and backend skills */}
            <div className="skills__container grid">
                {/* Frontend skills */}
                <Reveal>
                    <Frontend />
                </Reveal>
                {/* Backend skills */}
                <Reveal>
                    <Backend />
                </Reveal>
            </div>
        </section>
    )
}

export default Skills;
