import React from 'react';
import "./work.css";
import Works from './Works';

// Component representing the "Projects" section
const Work = () => {
    return (
        <section className="work section" id="projects">
            {/* Section title */}
            <h2 className="section__title">Projects</h2>
            {/* Section subtitle (empty in this example) */}
            <span className="section__subtitle"></span>
            {/* Component containing filters and projects */}
            <Works />
        </section>
    )
}

export default Work;
