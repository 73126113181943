import React from 'react';
import "./footer.css";
import { Reveal } from '../utils/Reveal';


const Footer = () => {
    return (
        <Reveal>
            <footer className="footer">
                <div className="footer__container container">
                    <Reveal>
                        <h1 className="footer__title">Benedetti Romain</h1>
                    </Reveal>
                    <Reveal>
                        <ul className="footer__list">
                            <li>
                                <a href="https://benedettir.emf-informatique.ch/" target="_blank" className="footer__link">School Website</a>
                            </li>

                            <li>
                                <a href="#skills" className="footer__link">Skills</a>
                            </li>

                            <li>
                                <a href="#projects" className="footer__link">Projects</a>
                            </li>
                        </ul>
                    </Reveal>

                    <Reveal>
                        <div className="footer__social">
                            <a href="https://www.linkedin.com/in/benedetti-romain/" className="footer__social-link" target="_blank">
                                <i class='bx bxl-linkedin'></i>
                            </a>

                            <a href="https://www.instagram.com/romarin.01/" className="footer__social-link" target="_blank">
                                <i class='bx bxl-instagram-alt'></i>
                            </a>

                            <a href="https://github.com/Touristiquer" className="footer__social-link" target="_blank">
                                <i class='bx bxl-github'></i>
                            </a>

                            <a href="https://www.youtube.com/channel/UCA6msX_DJQ2dUfDrkOJLk6g" className="footer__social-link" target="_blank">
                                <i class='bx bxl-youtube' ></i>
                            </a>

                            <a href="https://open.spotify.com/intl-fr/artist/7npGSjstCone2pNoNaYoSJ" className="footer__social-link" target="_blank">
                                <i class='bx bxl-spotify'></i>
                            </a>

                            <a href="https://soundcloud.com/touristiquement" className="footer__social-link" target="_blank">
                                <i class='bx bxl-soundcloud'></i>
                            </a>
                        </div>
                    </Reveal>
                    <Reveal>
                        <span className="footer__copy">
                            &#169; 2024 Benedetti Romain. All Rights Reserved
                        </span>
                    </Reveal>
                </div>
            </footer>
        </Reveal>
    )
}

export default Footer